// REDUX ACTION TYPES
export const TICK = 'TICK'
export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const RESET_MODAL_SHOW = 'RESET_MODAL_SHOW'
export const RESET_MODAL_HIDDEN = 'RESET_MODAL_HIDDEN'
export const LOGIN_MODAL_SHOW = 'LOGIN_MODAL_SHOW'
export const LOGIN_MODAL_HIDDEN = 'LOGIN_MODAL_HIDDEN'
export const SIGNUP_MODAL_SHOW = 'SIGNUP_MODAL_SHOW'
export const SIGNUP_MODAL_HIDDEN = 'SIGNUP_MODAL_HIDDEN'
export const SAVE_USER_MSG = 'SAVE_USER_MSG'
