import * as types from '../types/user'

// show login modal
export const showLoginModal = () => ({ type: types.LOGIN_MODAL_SHOW })

// hide login modal
export const hideLoginModal = () => ({ type: types.LOGIN_MODAL_HIDDEN })

// show login modal
export const showSignupModal = () => ({ type: types.SIGNUP_MODAL_SHOW })

// hide login modal
export const hideSignupModal = () => ({ type: types.SIGNUP_MODAL_HIDDEN })

// show login modal
export const showResetModal = () => ({ type: types.RESET_MODAL_SHOW })

// hide login modal
export const hideResetModal = () => ({ type: types.RESET_MODAL_HIDDEN })

// save user message
export const saveUserMsg = (content) => ({ type: types.SAVE_USER_MSG, content })
